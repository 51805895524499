import { Injectable, inject } from '@angular/core';
import {Socket} from 'socket.io-client';
import {ReplaySubject} from 'rxjs';
import {UserService} from './user/user.service';
import {EnvironmentService} from './environment.service';
import {WindowService} from './window.service';
import {environment} from '../../../environments/environment';
import { PlatformService } from 'ngx-unificator/services';

interface Md5Type {
  hashStr(str: string, raw?: true): Int32Array;
}

enum WS_RESPONS_TYPE {
  RECONNECT = 'reconnect', // Invalid data passed to socket io. For example: userId: 0
  ACCEPTED = 'accepted', // Event accepdet
  AUTH_FAILED = 'auth-failed', // When the user hash is not valid.
  NOTIFY = 'notify',
  NOTIFY_ACCEPTED = 'notificationAccepted',
}

enum WS_EVENTS {
  LOGIN = 'login',
  LOGOUT = 'logout',
  GAME_OPEN = 'game-open',
  GAME_CLOSE = 'game-close',
  DEPOSIT_SUCCESS = 'success_dep',
  DEPOSIT_FAILED = 'deposit-failed',
  CASHOUT_SUCCESS = 'success_cashout',
  CASHOUT_FAILE = 'cashout-failed',
  PAGE_OPEN = 'page-open',
  MOUSE_OUT = 'mouseout',
  MOUSE_IN = 'mousein',
}

const WAIT_TIME_AFTER_LOGIN = 10_000;

/**
 * Need install
 * socket.io-client => ^4.5.2",
 * ts-md5 =>  ^1.3.1
 */
@Injectable({
  providedIn: 'root'
})
export class WrSocketService {
  private _user = inject(UserService);
  private _env = inject(EnvironmentService);
  private _platform = inject(PlatformService);
  private _window = inject(WindowService);


  private _md5: Md5Type;
  private _hash: Int32Array = null;
  private _socket: Socket = null;
  private _query: any = {};

  private _isSocketConnected: boolean = null;
  private _isSocketConnected$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public depositSuccessNotify$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public cashoutSuccessNotify$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public get isSocketConnected(): boolean {
    return this._isSocketConnected;
  }

  /**
   * Load all libs and init connection
   * @returns
   */
  public async initWrSocket() {
    if (this._platform.isBrowser) {
      this._md5 = await (await import('ts-md5')).Md5;
      const { io } = await import('socket.io-client');
      this._createConnection(io);
    }
  }

  /**
   * Check hash & create connection
   * @param io
   */
  private _createConnection(io: any) {
    this._hash = this._generateUserHash();
    if (this._hash) {
      this._query = {
        userId: this._user.info.id,
        hash: this._hash,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        country: this._env.env.country.short,
        referer: !environment.production ? environment.app_host : this._window.nativeWindow.location.origin
      };
    }
    this._socket = io(environment.wr_websocket, {
      path: `/user-event`,
      query: this._query,
    });

    this._checkSocketConnection();
    this._checkSocketDisconnected();
    this._listenerSocketResponse();
  }

  /**
   * "When the socket connects, set the isSocketConnected property to true and emit the
   * isSocketConnected$ observable."
   * The next function is the one that will be called when the socket disconnects.
   */
  private _checkSocketConnection() {
    this._socket.on('connect', () => {
      this._isSocketConnected = true;
      this._isSocketConnected$.next(true);
    });
  }

  /**
   * If the socket is disconnected, set the isSocketConnected property to false and emit the new value to
   * the isSocketConnected$ observable.
   */
  private _checkSocketDisconnected() {
    this._socket.on('disconnect', () => {
      this._isSocketConnected = false;
      this._isSocketConnected$.next(false);
    });
  }

  /**
   * Listener socket responses
   */
  private _listenerSocketResponse() {
    this._reconnectResponse();
    this._notifyResponse();
    this._authFailedResponse();
  }

  private _reconnectResponse() {
    this._socket.on(WS_RESPONS_TYPE.RECONNECT, (data: { timeout: number }) => {
      this._socket.io.reconnection(false);
      this._socket.close();
      setTimeout(() => {
        this.initWrSocket();
      }, data.timeout * 1000);
    });
  }

  private _authFailedResponse() {
    this._socket.on(WS_RESPONS_TYPE.AUTH_FAILED, (data) => {
      this._socket.io.reconnection(false);
      this._socket.close();
    });
  }

  private _notifyResponse() {
    this._socket.on(WS_RESPONS_TYPE.NOTIFY, (data) => {

      if (data.event === WS_EVENTS.DEPOSIT_SUCCESS) {
        this.depositSuccessNotify$.next(data);
      }

      if (data.event === WS_EVENTS.CASHOUT_SUCCESS) {
        this.cashoutSuccessNotify$.next(data);
      }

      this._socket.emit(WS_RESPONS_TYPE.NOTIFY_ACCEPTED, {
        messageUid: data?.uid,
      });
    });
  }

  /**
   * It takes the user's last name, first name, date of birth, city, and nickname, and returns an MD5
   * hash of those values.
   * @returns A hash of the user's data.
   */
  private _generateUserHash() {
    const userData = {
      userId: this._user.info.id,
      last_name: this._user.info.last_name || '',
      first_name: this._user.info.first_name || '',
      date_of_birth: this._user.info.date_of_birth || '',
      city: this._user.info.city || '',
      nickname: this._user.info.nickname || '',
    };
    return this._md5.hashStr(Object.values(userData).join(''));
  }

  /**
   * Send data
   * @param event
   * @param url
   */
  private _sendData(event: WS_EVENTS, url: string = '', params: any = {}) {
    if (this.isSocketConnected && this._user.auth) {
      this._socket.emit('userEvent', {
        event,
        userId: this._user.info.id,
        currency: this._user.info.currency,
        referer: url || this._window.nativeWindow.location.href,
        ...params
      });
    }
  }

  /**
   * This function sends a login event to the server after 5 sec.
   * @param {string} [url] - string = ''
   */
  public sendEventLogin(url: string = '') {
    if (this._platform.isBrowser) {
      setTimeout(() => {
        this._sendData(WS_EVENTS.LOGIN, url);
      }, WAIT_TIME_AFTER_LOGIN);
    }
  }

  /**
   * This function sends a logout event to the server.
   * @param {string} [url] - string = ''
   */
  public sendEventLogout(url: string = '') {
    if (this._platform.isBrowser) {
      this._sendData(WS_EVENTS.LOGOUT, url);
      setTimeout(() => {
        if (this._socket) {
          this._socket.disconnect();
        }
      }, WAIT_TIME_AFTER_LOGIN);
    }
  }

  /**
   * It sends a message to the server with the event name and the url of the game.
   * @param gameData
   * @param {string} [url] - string = ''
   */
  public sendEventGameOpen(gameData: any, url: string = this._window.nativeWindow.location.href) {
    if (this._platform.isBrowser) {
      if (this.isSocketConnected) {
        this._sendData(WS_EVENTS.GAME_OPEN, url, gameData);
      } else {
        setTimeout(() => {
          this._sendData(WS_EVENTS.GAME_OPEN, url, gameData);
        }, WAIT_TIME_AFTER_LOGIN);
      }
    }
  }

  /**
   * This function sends a message to the server that the game has been closed.
   * @param gameData
   * @param {string} [url] - string = ''
   */
  public sendEventGameClose(gameData: any, url: string = '') {
    if (this._platform.isBrowser) {
      this._sendData(WS_EVENTS.GAME_CLOSE, url, gameData);
    }
  }

  /**
   * This function sends a deposit event to the server.
   * @param {string} [success] - boolean = ''
   * @param url - string
   */
  public sendEventDeposit(success: boolean, url: string = this._window.nativeWindow.location.href) {
    if (this._platform.isBrowser) {
      setTimeout(() => {
        this._sendData(success ? WS_EVENTS.DEPOSIT_SUCCESS : WS_EVENTS.DEPOSIT_FAILED, url);
      }, WAIT_TIME_AFTER_LOGIN);
    }
  }


  /**
   * This function sends a message to the server with the event name and the url of the page that the
   * user is on.
   * @param {string} [success] - boolean = ''
   * @param url - string
   */
  public sendEventCashout(success: boolean, url: string = this._window.nativeWindow.location.href) {
    if (this._platform.isBrowser) {
      setTimeout(() => {
        this._sendData(success ? WS_EVENTS.CASHOUT_SUCCESS : WS_EVENTS.CASHOUT_FAILE, url);
      }, WAIT_TIME_AFTER_LOGIN);
    }
  }


  /**
   * This function sends a message to the server that the page has been opened.
   * @param {string} [url] - string = ''
   */
  public sendEventPageOpen(url: string = this._window.nativeWindow.location.href) {
    // if (this._platform.isBrowser) {
    //   if (this.isSocketConnected) {
    //     this._sendData(WS_EVENTS.PAGE_OPEN, url);
    //   } else {
    //     setTimeout(() => {
    //       this._sendData(WS_EVENTS.PAGE_OPEN, url);
    //     }, WAIT_TIME_AFTER_LOGIN);
    //   }
    // }
  }


  /**
   * This function sends a message to the server that the mouse has left the page.
   * @param {string} [url] - string = ''
   */
  public sendEventMouseout(url: string = '') {
    // if (this._platform.isBrowser) {
    //   this._sendData(WS_EVENTS.MOUSE_OUT, url);
    // }
  }

  /**
   * It sends a message to the server with the event name and the url of the page the user is on.
   * @param {string} [url] - string = ''
   */
  public sendEventMousein(url: string = '') {
    // if (this._platform.isBrowser) {
    //   this._sendData(WS_EVENTS.MOUSE_IN, url);
    // }
  }

}
