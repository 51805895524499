import { computed, inject, Injectable, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { SsApiService } from '../api/ss-api.service';
import { switchMap, tap } from 'rxjs/operators';
import { SsPaymentsV2Service } from '../../vendor/ss-payments-v2/ss-payment.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EnvironmentService } from '../environment.service';
import { CORRECTED_PHONE_NUMBERS } from 'ngx-unificator/helpers';

export enum PhoneStatus {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified'
}
export interface PhoneState {
  isHasVerified: boolean;
  phonePrefix: string;
  phoneMask: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserPhoneService {
  public state = computed(() => this._state());

  private _state = signal<PhoneState>({
    isHasVerified: false,
    phonePrefix: '',
    phoneMask: '',
  });

  private _api = inject(SsApiService);
  private _env = inject(EnvironmentService);
  private _ssPayments = inject(SsPaymentsV2Service);

  constructor() {
    this._updatePhoneStoreMask();
  }

  /**
   * Returns list of player phone numbers
   */
  getPhoneList(): Observable<any> {
    return this._api.playerPhone().pipe(
      tap(list => {
        this._state.update(store => {
          return {
            ...store,
            isHasVerified: list.some(phone =>
              phone.verified_status === PhoneStatus.VERIFIED),
          };
        });
      }),
    );
  }

  /**
   * Add user phone
   *
   * @param data
   */
  addPhone(data: object): Observable<any> {
    return this._api.postPlayerPhone({
      phone: data
    }).pipe(
      switchMap(() => this._ssPayments.resetCache())
    );
  }

  /**
   * Delete user phone
   */
  deletePhone(): Observable<any> {
    return this._api.deletePlayerPhone().pipe(
      switchMap(() => this._ssPayments.resetCache())
    );
  }

  /**
   * Verify player [hone
   *
   * @param code
   */
  verifyPhone(code: string): Observable<any> {
    return this._api.playerPhoneConfirm({
      phone: {
        code
      }
    });
  }

  public updatePrefixStore(callingCode: string) {
    this._state.update(store => {
      return {
        ...store,
        phonePrefix: `+${callingCode}`,
      };
    });
  }

  public updateMaskStore(country: string) {
    this._state.update(store => {
      return {
        ...store,
        phoneMask: this._getPhoneExampleMask(country),
      };
    });
  }

  private _getPhoneExampleMask(country: any): string {
    const exampleNumber = CORRECTED_PHONE_NUMBERS[country?.toUpperCase()];
    const strippedNumber = exampleNumber.replace(`${this.state().phonePrefix}`, '').trim();
    return `${strippedNumber.replace(/\d/g, '0')}`;
  }

  private _updatePhoneStoreMask() {
    this._env.env$
      .pipe(
        takeUntilDestroyed(),
        tap(({ data }) => {
          this.updateMaskStore(data?.country?.short);
          this.updatePrefixStore(data?.country?.callingCode);
        }),
      )
      .subscribe();
  }
}
