import { Component, OnInit, viewChild, ViewContainerRef } from '@angular/core';
import { showHide } from '../../modal-animations';
import { Modal } from '../../modal';

@Component({
  selector: 'app-modal-template-large',
  templateUrl: './modal-template-large.component.html',
  styleUrls: ['./modal-template-large.component.scss'],
  animations: [showHide],
})
export class ModalTemplateLargeComponent extends Modal implements OnInit {

  readonly contentRef = viewChild('content', { read: ViewContainerRef });

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
