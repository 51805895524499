import { inject, Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { environment } from '../../../../environments/environment';
import { LanguageService } from '../language/language.service';
import { Router } from '@angular/router';
import { CmsApiService } from '../api/cms-api.service';
import { HostRequestsInterceptor } from '../../interceptors/host-requests.interceptor';
import { ToastMessageService } from '../../shared/modules/toast-message/toast-message.service';
import { ModalService } from '../../modal/modal.service';

/**
 * Game mode list
 */
export enum GameMode {
  DEMO = 'demo',
  REAL = 'real',
}

@Injectable({
  providedIn: 'root',
})
export class GamesLauncherService {
  private _user = inject(UserService);
  private _language = inject(LanguageService);
  private _modal = inject(ModalService);
  private _router = inject(Router);
  private _cmsApi = inject(CmsApiService);
  private _toast = inject(ToastMessageService);
  private _hostInterceptor = inject(HostRequestsInterceptor);

  /**
   * Is game launched now
   */
  public gameLaunched: boolean;

  /**
   * Current launched game
   */
  public currentGame: any;

  /**
   * Returns iframe source for provided game
   *
   * @param game
   * @param demo
   */
  public gameSource(game: any, demo: boolean = false): { url: string; mode: GameMode } {
    const idObject = this.resolveGameId(game, demo);

    return {
      url: this._hostInterceptor.overrideUrl(`${environment.ss_host}/${this._language.current}/games/${game.externalId}/${idObject.id}`),
      mode: idObject.mode,
    };
  }

  /**
   * Returns external id for provided game
   *
   * @param game
   * @param demo
   * @private
   */
  public resolveGameId(game: any, demo: boolean = false): { id: string; mode: GameMode } {
    try {
      if (!this._user.auth || demo || !this._user.info.currency || !game.externalData.real[this._user.info.currency]) {
        return {
          id: game.externalData.demo,
          mode: GameMode.DEMO,
        };
      } else {
        return {
          id: game.externalData.real[this._user.info.currency].id,
          mode: GameMode.REAL,
        };
      }
    } catch (e) {
    }
  }

  /**
   * Check if game available for current user
   *
   * @param game
   */
  public checkGameAvailability(game): boolean {
    if (game) {
      if (!this._user.auth && game.live || !this._user.auth && game.nodemo) {
        this._user.authUser().then();
      }

      if (this._user.auth && !game.externalData.real[this._user.info.currency]) {
        this._gameNotAvailablePopup({
          currencyList: Object.keys(game.externalData.real),
        });
        return false;
      }

      return true;
    }
    this._gameNotAvailablePopup();
    return false;
  }

  /**
   * Open game by external id
   *
   * @param game
   */
  openGameByExternalId(game) {
    this._cmsApi.gameList({ 'external_id[]': game.identifier }).subscribe(e => {
      if (e.data.gameList) {
        this._router.navigateByUrl('/play/' + e.data.gameList[0].slug, {
          state: {
            game: e.data.gameList[0],
          },
        });
      } else {
        this._toast.warning('This game is not available');
      }
    });
  }

  /**
   * Show game not available popup
   *
   * @private
   */
  private async _gameNotAvailablePopup(data: object = {}) {
    this._router.navigateByUrl('/games').then(async () => {
      const component = await import('../../modal/components/lazy/game-not-available-modal/game-not-available-modal.component');
      await this._modal.openLazy(
        component?.GameNotAvailableModalComponent,
        {
          data,
        },
      );
    });
  }
}
