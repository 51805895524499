import {
  Arguments,
  Form,
  LibError,
  MethodBannerField,
  MethodDepositCryptoAddressField,
  Payment,
  PaymentsLib,
  RedirectRoutes,
  Results,
} from 'payments-lib-types';
import { CommissionType } from 'payments-lib-types/types/terms-of-service';

export type CustomPaymentsLibConfig = { _isLoaded: boolean };
export type PaymentsLibType = Partial<PaymentsLib & CustomPaymentsLibConfig>;
export type PaymentsMethod = Partial<Omit<Payment.Method, 'brand'> & {
  id: string;
  brand: string;
  originBrand: string;
  provider: string;
  type: string;
  img: string;
  custom_img: string;
  currency?: string;
  originalCryptoCurrency?: string;
  ourImg?: string;
}>;

export type PaymentsMethodField = Partial<{
  field: string;
  fieldName: string; // Field identifier
  proposedValue: string | null; // Proposed value for input
  translationKey: string | null;
  label: string | null;
  placeholder: string | null;
  type: string;
  href: string | null;
  address: string | null;
  limits?: {
    min: number | null;
    max: number | null;
  };
  options: Form.Option[];
  htmlContent: string;
  identifier: string;
  secondaryFieldName: string;
  secondaryOptionsMapByMainValue: Record<string, Form.Option[]>;
  secondaryTranslationKey: string | null;
  secondaryLabel: string | null;
  secondaryPlaceholder: string | null;
  valueStep: number | null;
  protocols: string[];
  readonly: boolean;
  addressByProtocol: Record<string, MethodDepositCryptoAddressField>;
  bannersByProtocol: Record<string, MethodBannerField[]>;
  custom_label?: any;
  bottomDescription?: any;
  topDescription?: any;
  hint?: any;
  bottomPlaceholder?: any;
  hidePlaceholder?: any;
  tip?: string;
}>;

export type Commissions =
  | {
  type: CommissionType.Percent;
  percent: number; // Deposit percent commission
}
  | {
  type: CommissionType.RangePercent;
  lowerBoundPercent: number; // Deposit lower bound commission percent
  upperBoundPercent: number; // Deposit upper bound commission percent
}
  | {
  type: CommissionType.Fixed;
  cents: number; // Deposit fixed commission in cents
}
  | { type: CommissionType.None } | any;

export type SavedProfile = {
  id: string;
  title: string;
  isRemoveAvailable: boolean;
};

export { type Results, type RedirectRoutes, LibError, Payment, Arguments, Form };

/**
 * Cookie name of last used fiat currency
 */
export const LAST_USED_FIAT_CURRENCY = 'LAST_USED_FIAT_CURRENCY';

/**
 * Cookie name of last used crypto currency
 */
export const LAST_USED_CRYPTO_CURRENCY = 'LAST_USED_CRYPTO_CURRENCY';

/**
 * Payment type (crypto, fiat or cashout)
 */
export enum CustomPaymentType {
  CRYPTO = 'crypto',
  FIAT = 'fiat',
  BUY_CRYPTO = 'buy-crypto'
}
