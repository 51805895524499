import { ModalComponent } from './modal.component';
import { Modal, ModalOptions } from './modal';
import { ComponentRef, Inject, Signal, ViewContainerRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { ScrollService } from '../services/scroll.service';

export interface ModalContent {
  modal?: ModalRef;
}

export class ModalRef {

  private _result$ = new Subject<any>();

  public options: ModalOptions;

  constructor(
    private _modalContainer: ComponentRef<ModalComponent>,
    private _modal: ComponentRef<Modal>,
    private _scroll: ScrollService,
    @Inject(DOCUMENT) private _document: Document,
  ) {
    this._modal.instance.instance = this;
    this.options = this._modal.instance.options;
  }

  close(output: any = null): void {
    this._result$.next(output);
    this._destroy();
  }

  dismiss(output: any): void {
    this._result$.error(output);
    this._destroy();
  }

  onResult(): Observable<any> {
    return this._result$.asObservable();
  }

  private _destroy(): void {
    this._scroll.UnblockScroll();
    this._modal.destroy();
    this._modalContainer.destroy();
    this._result$.complete();
  }

}

export class ModalTemplateRef {
  public contentRef: Signal<ViewContainerRef>;
  public modal?: ModalRef;
}
