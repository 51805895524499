import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class WindowService {
  private _window: Window;
  constructor() {
    const platformId = inject(PLATFORM_ID);

    if (!isPlatformBrowser(platformId)) {
      this._window = {navigator: {userAgent: 'fakeAgent'}, location: {pathname: ''}} as Window;
    } else {
      this._window = window;
    }
  }

  get nativeWindow(): any {
    return this._window;
  }

  get isIphoneDevices() {
    return this._isDetectOs(/ipad|iphone|ipod/i);
  }

  get isMacOsDevice() {
    return this._isDetectOs(/mac/i);
  }

  /**
   * Return true if application runs on specific OS
   * @private
   */
  private _isDetectOs(regexp: RegExp): boolean {
    return regexp.test(this._window.navigator.userAgent) || (!!this._window.navigator.platform && regexp.test(this._window.navigator.platform));
  }
}
