<form class='form' [formGroup]='form' (ngSubmit)='onSubmit()'>
  @if (!hideTitle()) {
    <div class='form__title'>{{ 't.update-profile' | translate }}</div>
  }

  <div class='input-container'>
    @for (field of fieldList; track $index) {
      @switch (field.type) {
        @case (FieldType.TEXT) {
          @switch (field.field) {
            @case ('city') {
              <app-form-input [control]='form?.get(field.field)' [label]='field?.label | translate' class="gradient"
                              [style.z-index]="6">
                <input type='text' class='input input--simple' [name]='field.field' [id]='field.field'
                       [formControlName]='field?.field' autocomplete='nope'>
                <ng-container *ngTemplateOutlet='citiesSuggestionList'></ng-container>
              </app-form-input>
            }
            @case ('address') {
              <app-form-input [control]='form?.get(field.field)' [label]='field?.label | translate' class="gradient"
                              [style.z-index]="5">
                <input type='text' class='input input--simple' [name]='field.field' [id]='field.field'
                       [formControlName]='field?.field' autocomplete='nope'>
                <ng-container *ngTemplateOutlet='addressesSuggestionList'></ng-container>
              </app-form-input>
            }
            @case ('postal_code') {
              <app-form-input [control]='form?.get(field.field)' [label]='field?.label | translate' class="gradient"
                              [style.z-index]="4">
                <input type='text' class='input input--simple' [name]='field.field' [id]='field.field'
                       [formControlName]='field?.field' autocomplete='nope'>
                <ng-container *ngTemplateOutlet='zipCodeSuggestionList'></ng-container>
              </app-form-input>
            }
            @default {
              <app-form-input [control]='form?.get(field.field)' [label]='field?.label | translate' class="gradient">
                <input type='text' class='input input--simple' [name]='field.field' [id]='field.field'
                       [formControlName]='field?.field'
                       [mask]="userInfo.isCA && field?.field === 'postal_code' ? 'S0S 0S0' : null"
                       [dropSpecialCharacters]="!(userInfo.isCA && field?.field === 'postal_code')"
                       [validation]='false'
                       [ngClass]="{uppercase: userInfo.isCA && field?.field === 'postal_code'}"
                >
              </app-form-input>
            }
          }
        }
        @case (FieldType.DATE) {
          <app-form-input [control]='form?.get(field.field)' [label]='field?.label | translate' class="gradient">
            <input
              appDobMask
              type='text' class='input input--simple' [name]='field.field' [id]='field.field'
              [formControlName]='field?.field'>
          </app-form-input>
        }
        @case (FieldType.SELECT) {
          <app-form-input>
            @if (field.options | async; as options) {
              <app-select class='select' [formControlName]='field.field'
                          [id]='field.field'>
                <app-select-header class='select__header gradient'>{{ field.label | translate }}</app-select-header>
                <app-select-dropdown class='select__dropdown'>
                  @for (option of options; track $index) {
                    <app-select-option class='select__option' [value]='option[0]'>
                      {{ field.field !== 'country' && field.field !== 'state' ? (option[1] | translate) : option[1] }}
                    </app-select-option>
                  }
                </app-select-dropdown>
              </app-select>
            }
          </app-form-input>
        }
        @case (FieldType.PHONE) {
          <app-form-input [control]='form?.get(field.field)' class="gradient">
            <app-phone-form
              [formControlName]='field?.field' [id]='field.field'>
            </app-phone-form>
          </app-form-input>
        }
        @case (FieldType.RADIO) {
          <app-form-input [control]='form?.get(field.field)' class='input-item'>
            @if (field.options | async; as options) {
              <div class='input--radio'>
                @for (option of options; track $index) {
                  <label class='radio'>
                    <input
                      type='radio'
                      [value]='option[0]'
                      [id]='field.field'
                      [formControlName]='field.field' />
                    <span class='radio__view'></span>
                    <span class='radio__label'>{{ option[1] | translate }}</span>
                  </label>
                }
              </div>
            }
          </app-form-input>
        }
      }
    }
  </div>

  <div class='submit-container'>
    @if (buttonName === 't.sign-up') {
      <button class='btn btn--filled-primary' [loading]='auth.loading' type='submit'>
        {{ buttonName | translate }}
      </button>
    } @else {
      <button class='btn btn--filled-primary' [loading]='userProfile.loading' type='submit'>
        {{ buttonName | translate }}
      </button>
    }
  </div>
</form>

<ng-template #citiesSuggestionList>
  @if (userInfo.citiesSuggestion && userInfo.citiesSuggestion.length) {
    <div
      class="suggestion-list suggestion-list__address">
      @for (city of userInfo.citiesSuggestion; track $index) {
        <div
          class="suggestion-list__item"
          (click)="userInfo.selectSuggestion(form, city, 'city')">
          {{ city?.city }}
        </div>
      }
    </div>
  }
</ng-template>

<ng-template #addressesSuggestionList>
  @if (userInfo.addressesSuggestion && userInfo.addressesSuggestion.length) {
    <div
      class="suggestion-list suggestion-list__address">
      @for (suggestion of userInfo.addressesSuggestion; track $index) {
        <div class="suggestion-list__item"
             (click)="userInfo.selectSuggestion(form, suggestion, 'address')">
          <span>{{ suggestion?.formatted }}</span>
        </div>
      }
    </div>
  }
</ng-template>

<ng-template #zipCodeSuggestionList>
  @if (userInfo.zipCodeSuggestion && userInfo.zipCodeSuggestion.length) {
    <div
      class="suggestion-list suggestion-list__address">
      @for (suggestion of userInfo.zipCodeSuggestion; track $index) {
        <div class="suggestion-list__item"
             (click)="userInfo.selectSuggestion(form, suggestion, 'postal_code')">
          <span>{{ suggestion?.postcode }}</span>
        </div>
      }
    </div>
  }
</ng-template>
