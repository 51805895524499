import { Component, Input, OnInit, inject, input, output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UserFieldDescriptor, UserFieldType } from '../../../helpers/user/user-fields.data';
import { FieldDescriptor, UserInfoService } from '../../../services/user/user-info.service';
import { UserProfileService } from '../../../services/user/user-profile.service';
import { UserService } from '../../../services/user/user.service';
import { EnvironmentService } from '../../../services/environment.service';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BtnComponent } from '../btn/btn.component';
import { PhoneFormComponent } from '../phone-form/phone-form.component';
import { SelectOptionComponent } from '../../modules/select/select-option/select-option.component';
import { SelectDropdownComponent } from '../../modules/select/select-dropdown/select-dropdown.component';
import { SelectHeaderComponent } from '../../modules/select/select-header/select-header.component';
import { SelectComponent } from '../../modules/select/select.component';
import { DobMaskDirective } from '../../directives/dob-mask.directive';
import { NgxMaskDirective } from 'ngx-mask';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { FormInputComponent } from '../form-input/form-input.component';
import { TranslatePipe } from '../../translation/translate.pipe';
import { ArrayService } from 'ngx-unificator/services';
import { UserAuthService } from '../../../services/user/user-auth.service';

@UntilDestroy()
@Component({
    selector: 'app-auth-fields-missed',
    templateUrl: './auth-fields-missed.component.html',
    styleUrls: ['./auth-fields-missed.component.scss'],
    imports: [
        ReactiveFormsModule,
        FormInputComponent,
        NgTemplateOutlet,
        NgxMaskDirective,
        NgClass,
        DobMaskDirective,
        SelectComponent,
        SelectHeaderComponent,
        SelectDropdownComponent,
        SelectOptionComponent,
        PhoneFormComponent,
        BtnComponent,
        AsyncPipe,
        TranslatePipe
    ]
})
export class AuthFieldsMissedComponent implements OnInit {
  private _fb = inject(FormBuilder);
  private _array = inject(ArrayService);
  userProfile = inject(UserProfileService);
  user = inject(UserService);
  userInfo = inject(UserInfoService);
  env = inject(EnvironmentService);
  auth = inject(UserAuthService);


  readonly fieldsList$ = input<Observable<(UserFieldDescriptor & FieldDescriptor)[]>>(undefined);
  readonly hideTitle = input<boolean>(false);
  readonly selectedCountry = input<string>(undefined);
  @Input() buttonName: string = 't.save';

  readonly onSubmit$ = output<FormGroup>();

  /**
   * Missed fields form
   */
  public form: FormGroup = this._fb.group({});

  public readonly FieldType = UserFieldType;

  public fieldList: (UserFieldDescriptor & FieldDescriptor)[] = [];

  ngOnInit() {
    this._initForm();
  }

  public onSubmit() {
    this.onSubmit$.emit(this.form);
  }

  /**
   * Init required form fields
   * @private
   */
  private _initForm() {
    this.fieldsList$().pipe(
      untilDestroyed(this),
    ).subscribe(e => {
      this.fieldList = e;
      this.fieldList = this._array.mapOrder(this.fieldList.filter(field => field.field !== 'nickname'),
        [
          'first_name',
          'last_name',
          'gender',
          'date_of_birth',
          'country',
          'state',
          'city',
          'address',
          'postal_code',
          'mobile_phone'
        ], 'field');
      this.form = this._buildForm(this.fieldList);
      this.userInfo.initAutocomplete(this.form, this.selectedCountry());
    });
  }

  private _buildForm(fields: UserFieldDescriptor[] & FieldDescriptor[] | any): FormGroup {
    const form: FormGroup = new FormGroup({});
    fields.forEach((field: UserFieldDescriptor & FieldDescriptor) => {
      form.addControl(field.field, this._fb.control(null, field.validators));
    });
    return form;
  }
}
