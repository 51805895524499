import { Component, forwardRef, inject, OnInit, viewChild } from '@angular/core';
import { UserPhoneService } from '../../../services/user/user-phone.service';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgModel,
  ReactiveFormsModule,
} from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { CommonDataService, CountryList$ } from '../../../services/common-data.service';
import { EnvironmentService } from '../../../services/environment.service';
import { first, tap } from 'rxjs/operators';
import { AsyncPipe, NgClass } from '@angular/common';
import { ClickOutsideDirective } from 'ngx-unificator/directives';
import { NgxMaskDirective } from 'ngx-mask';
import { TranslatePipe } from '../../translation/translate.pipe';

@Component({
  selector: 'app-phone-form',
  templateUrl: './phone-form.component.html',
  styleUrls: ['./phone-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneFormComponent),
      multi: true,
    },
  ],
  imports: [
    ClickOutsideDirective,
    NgClass,
    ReactiveFormsModule,
    NgxMaskDirective,
    FormsModule,
    AsyncPipe,
    TranslatePipe,
  ],
})
export class PhoneFormComponent implements OnInit, ControlValueAccessor {
  userPhone = inject(UserPhoneService);
  env = inject(EnvironmentService);
  private _data = inject(CommonDataService);

  /**
   * Input model
   */
  readonly inputModel = viewChild<NgModel>('inputModel');

  /**
   * Current flag country
   */
  public currentCountry: string;

  /**
   * Search control
   */
  public search: FormControl = new FormControl('');

  /**
   * Is country list open
   */
  private _open: boolean;

  /**
   * Country list after filter
   */
  public filteredCountryList$: ReplaySubject<any[]> = CountryList$;

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  set value(val) {
    const resolvedValue = this.userPhone.state().phonePrefix + val;
    this.onChange(resolvedValue);
    this.onTouch(resolvedValue);
  }

  get isOpen(): boolean {
    return this._open;
  }

  constructor() {
    this.env.env$
      .pipe(
        first(),
        tap(({ data }) => {
          this.onSelect(data.country);
        }),
      )
      .subscribe();
  }

  ngOnInit() {
    this._onHandleSearch();
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  /**
   * Open countries list
   */
  onOpen() {
    this._open = true;
  }

  /**
   * Close countries list
   */
  onClose() {
    this._open = false;
  }

  onSelect(country) {
    this.onClose();
    this.currentCountry = country.short;
    this.userPhone.updatePrefixStore(country?.callingCode);
    this.userPhone.updateMaskStore(country?.short);
    this.inputModel()?.reset();
  }

  /**
   * Handle search form and filter countries
   *
   * @private
   */
  private _onHandleSearch() {
    this.search.valueChanges
      .pipe(
        tap(value =>
          this.filteredCountryList$.next(
            this._data.countryList.filter(country => country.name.toLowerCase().includes(value.toLowerCase())),
          ),
        ),
      )
      .subscribe();
  }
}
